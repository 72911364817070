<template>
  <div class="main">
    <div class="row">
      <div class="col-12">
        <img
          src="/img/imagem-statistics.png"
          class="thumb"
          alt="Imagem Statistics"
        />
      </div>
    </div>

    <div class="row infos pdd-0-15">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-cargo.png" alt="icone barco" />
          <p>
            <span>
              <i class="fa fa-spinner fa-spin" v-if="!mounted" />{{
                qty_vessels
              }}</span
            >
            Vessels in this period
          </p>
        </div>
      </div>

      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-pesso.png" alt="icone barco" />
          <p>
            <span
              ><i class="fa fa-spinner fa-spin" v-if="!mounted" />{{
                qty_toneladas ? qty_toneladas + "T" : ""
              }}</span
            >
            Quantity in tons
          </p>
        </div>
      </div>

      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-data.png" alt="icone barco" />
          <p>
            <span>
              <i class="fa fa-spinner fa-spin" v-if="!mounted" />
              {{ Math.round(qty_waiting_days) }}
            </span>
            Waiting days
          </p>
        </div>
      </div>
    </div>

    <div class="fundo">
      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>Dashboard</h2>
          <form>
            <div class="row">
              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                  multiple
                  label="Ports"
                  placeholder="Select the ports"
                  :options="portos || []"
                  optionsValue="name"
                  inputClasses="input"
                  v-model="search.porto_id"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                  multiple
                  label="Comms"
                  placeholder="Select the comms"
                  :options="cargas || []"
                  optionsValue="name"
                  inputClasses="input"
                  v-model="search.carga_tipo_id"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                  multiple
                  inputClasses="input"
                  label="Charterers"
                  placeholder="Select the charterers"
                  :options="afretadores || []"
                  optionsValue="name"
                  v-model="search.afretador_id"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <multiple-select
                  multiple
                  inputClasses="input"
                  label="Disport"
                  placeholder="Select the destination port"
                  :options="lineupPortos || []"
                  optionsValue="port_name"
                  v-model="search.destination_port"
                />
              </div>
            </div>

            <div class="row">
              <div
                class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12"
                v-if="!$root.isClient"
              >
                <multiple-select
                  multiple
                  inputClasses="input"
                  label="Naabsa vessels"
                  placeholder="Select naabsa vessels"
                  optionsKey="name"
                  :options="clientTypes()"
                  v-model="search.client_type"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <base-input
                  type="date"
                  alternative=""
                  label="Start period"
                  input-classes="form-control-alternative"
                  v-model.lazy="search.periodo_inicio"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <base-input
                  type="date"
                  alternative=""
                  label="End period"
                  input-classes="form-control-alternative"
                  v-model.lazy="search.periodo_fim"
                />
              </div>

              <div class="mrg-top-30 col-sl-3 col-lg-3 col-md-6 col-12">
                <button
                  class="submit text-center cursor-pointer w-100"
                  style="margin-top: 32px"
                  :disabled="!mounted"
                  value="Search"
                  @click="listar()"
                >
                  <i class="fa fa-spinner fa-spin" v-if="!mounted" /> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>
            Share per Charterers
            <i class="fa fa-spinner fa-spin" v-if="!mounted" />
          </h2>

          <div class="form-row">
            <div class="col-md-3">
              <div
                class="blc-tabela zebrada scroll"
                style="border-bottom: none"
              >
                <table
                  class="zebrada zebra2"
                  style="min-width: auto"
                  v-if="mounted"
                >
                  <thead>
                    <tr>
                      <th class="text-left p-2">Charter</th>
                      <th class="text-right p-2">Vessel</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(afretador, index) in afretadoresChart.chartData
                        .labels"
                    >
                      <td class="text-left">{{ afretador }}</td>
                      <td class="text-right px-4">
                        {{ getChartDataByIndex(afretadoresChart, index) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-3"></div>

            <div class="col-12 col-md-6" style="position: relative">
              <small
                :style="`position: absolute;
                    top: 10px;
                    right: 115px;
                    font-weight: bold;
                    z-index: 99;
                    text-transform: uppercase;
                    ${mounted ? '' : 'display:none'}
                    `"
                >Charterers</small
              >
              <google-chart
                v-if="mounted"
                type="PieChart"
                :data="afretadoresChart.chartData"
                :extra-options="{
                  width: '100%',
                  height: 350,
                }"
                class="chart2"
                :colors="portosChart.chartData.datasets[0].backgroundColor"
                :handle-label="
                  (label, data) => {
                    return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>
            Share per Destination
            <i class="fa fa-spinner fa-spin" v-if="!mounted" />
          </h2>

          <div class="row justify-content-end">
            <div class="col-12 col-md-6" style="position: relative">
              <small
                :style="`position: absolute;
          bottom: 30px;
          left: 90px;
          font-weight: bold;
          z-index: 99;
          text-transform: uppercase;
          ${mounted ? '' : 'display:none'}
          `"
                >Vessels</small
              >
              <google-chart
                v-if="mounted"
                type="GeoChart"
                :data="portosChart.chartData"
                :extra-options="{
                  is3D: true,
                  colorAxis: { colors: ['#bfe6ff', '#0d2bff'] },
                }"
              />
            </div>
            <div class="col-12 col-md-6" style="position: relative">
              <small
                :style="`position: absolute;
          top: 30px;
          right: 115px;
          font-weight: bold;
          z-index: 99;
          text-transform: uppercase;
          ${mounted ? '' : 'display:none'}
          `"
                >Destination</small
              >
              <google-chart
                v-if="mounted"
                type="PieChart"
                :data="portosChart.chartData"
                :extra-options="{ pieHole: 0.6 }"
                :handle-label="
                  (label, data) => {
                    return `${label}: ${data} VESSEL${data > 1 ? 'S' : ''}`;
                  }
                "
                :colors="portosChart.chartData.datasets[0].backgroundColor"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="blc-inf-geral">
        <div class="blc-item">
          <h2>
            Commodities and Quantities
            <i class="fa fa-spinner fa-spin" v-if="!mounted" />
          </h2>

          <div class="form-row">
            <div class="col-12 col-md-6" style="position: relative">
              <small
                :style="`position: absolute;
          top: 30px;
          right: 150px;
          font-weight: bold;
          z-index: 99;
          text-transform: uppercase;
          ${mounted ? '' : 'display:none'}
          `"
                >Comms</small
              >
              <google-chart
                v-if="mounted"
                type="PieChart"
                :data="cargasChart.chartData"
                :extra-options="{ pieHole: 0.6 }"
                :handle-label="
                  (label, data) => {
                    return `${label}: ${formatNumber(data)} t`;
                  }
                "
              />
            </div>
            <div class="col-12 col-md-6 p-0">
              <vertical-bar-chart
                :chart="cargasChart.chartData"
                class
                v-if="mounted"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import PieChart from "@/components/Charts/PieChart";
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";
import GoogleChart from "../components/Dashboard/GoogleChart.vue";
import VerticalBarChart from "../components/Dashboard/VerticalBarChart.vue";
import Service from "@/services/Dashboard";
import "../assets/js/google-chart";

export default {
  components: {
    LineChart,
    BarChart,
    PieChart,
    PageVisitsTable,
    SocialTrafficTable,
    GoogleChart,
    VerticalBarChart,
  },
  data() {
    return {
      mounted: false,
      qty_vessels: "",
      qty_toneladas: "",
      qty_waiting_days: "",
      portos: [],
      cargas: [],
      navios: [],
      afretadores: [],
      lineupPortos: [],
      search: {
        carga_tipo_id: [],
        porto_id: [],
        afretador_id: [],
        destination_port: [],
        periodo_inicio: null,
        periodo_fim: null,
        navio_id: [],
        client_type: [],
      },
      cargasChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#073763",
                "#1D4971",
                "#345B7F",
                "#4A6D8D",
                "#607F9B",
                "#7791A9",
                "#8DA3B7",
                "#A3B5C5",
                "#BAC7D3",
                "#D0D9E1",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.getPieOptions(70),
      },
      portosChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#0d2bff",
                "#1a52ff",
                "#4070ff",
                "#5a8aff",
                "#6b9fff",
                "#81b5ff",
                "#95c8ff",
                "#a1d1ff",
                "#b3daff",
                "#bfe6ff",
                "#c8e6c9",
                // "#073763",
                // "#1D4971",
                // "#345B7F",
                // "#4A6D8D",
                // "#607F9B",
                // "#7791A9",
                // "#8DA3B7",
                // "#A3B5C5",
                // "#BAC7D3",
                // "#D0D9E1",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.getPieOptions(70),
      },
      afretadoresChart: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#0d2bff",
                "#1a52ff",
                "#4070ff",
                "#5a8aff",
                "#6b9fff",
                "#81b5ff",
                "#95c8ff",
                "#a1d1ff",
                "#b3daff",
                "#bfe6ff",
                "#c8e6c9",
              ],
            },
          ],
          labels: [],
        },
        extraOptions: chartConfigs.pieChartOptions,
      },
    };
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getRandomColor() {
      const color = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + color;
    },
    async listar() {
      this.mounted = false;
      this.qty_vessels = "";
      this.qty_toneladas = "";
      this.qty_waiting_days = "";
      const {
        qty_toneladas,
        qty_vessels,
        qty_waiting_days,
        afretadores,
        cargas,
        portos,
      } = (await Service.get(this.search)).data;
      this.qty_waiting_days = qty_waiting_days;
      this.qty_toneladas = qty_toneladas;
      this.qty_vessels = qty_vessels;
      this.setChart(afretadores, "afretadoresChart", "lineups_count", "name");
      this.setChart(cargas, "cargasChart", "lineups_sum_qty");
      this.setChart(portos, "portosChart", "lineups_count", "pais");
      this.mounted = true;
    },
    setChart(values, chart, key, name = "name") {
      this[chart].chartData.labels = [];
      this[chart].chartData.datasets[0].data = [];
      for (let i = 0; i < values.length; i++) {
        this[chart].chartData.labels[i] = values[i][name];
        this[chart].chartData.datasets[0].data[i] = values[i][key];
      }
    },
    getChartDataByIndex(chart, index) {
      return chart.chartData.datasets[0].data[index];
    },
    getChartBackGroundColorByIndex(chart, index) {
      return chart.chartData.datasets[0].backgroundColor[index];
    },
    async setFields() {
      const { cargas, portos, lineupPortos, afretadores, navios } = (
        await Service.fields()
      ).data;
      this.cargas = cargas;
      this.portos = portos;
      this.lineupPortos = lineupPortos;
      this.afretadores = afretadores;
      this.navios = navios;
      this.listar();
    },
    clientTypes() {
      return Service.clientTypes();
    },
  },
  mounted() {
    this.setFields();
  },
};
</script>
<style>
#filters-row label {
  color: white !important;
}
.ball {
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
}
.table td {
  padding: 5px 2px;
}
.chart {
  position: relative;
  height: 350px;
}
.chart2 {
  position: relative;
  height: 300px;
}

.zebra2 td {
  padding: 5px 10px;
  font-family: var(--font-montserrat);
  font-weight: var(--font-light);
  color: #242629;
  font-size: 15px;
  text-transform: uppercase;
  border-bottom: solid 1px #282c363d;
}
</style>
